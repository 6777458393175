import React from "react";
import Routing from "./Routing.js";

export default function App() {
  return (
    <div>
      <Routing />
    </div>
  );
}
